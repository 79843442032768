exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-autonomous-dreams-tsx": () => import("./../../../src/pages/autonomous-dreams.tsx" /* webpackChunkName: "component---src-pages-autonomous-dreams-tsx" */),
  "component---src-pages-end-tsx": () => import("./../../../src/pages/end.tsx" /* webpackChunkName: "component---src-pages-end-tsx" */),
  "component---src-pages-face-value-tsx": () => import("./../../../src/pages/face-value.tsx" /* webpackChunkName: "component---src-pages-face-value-tsx" */),
  "component---src-pages-feedback-tsx": () => import("./../../../src/pages/feedback.tsx" /* webpackChunkName: "component---src-pages-feedback-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-intro-tsx": () => import("./../../../src/pages/intro.tsx" /* webpackChunkName: "component---src-pages-intro-tsx" */),
  "component---src-pages-people-behind-skr-tsx": () => import("./../../../src/pages/people-behind-skr.tsx" /* webpackChunkName: "component---src-pages-people-behind-skr-tsx" */),
  "component---src-pages-sound-of-change-tsx": () => import("./../../../src/pages/sound-of-change.tsx" /* webpackChunkName: "component---src-pages-sound-of-change-tsx" */),
  "component---src-pages-targeted-tsx": () => import("./../../../src/pages/targeted.tsx" /* webpackChunkName: "component---src-pages-targeted-tsx" */)
}

